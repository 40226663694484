import React from "react";

export const SubSectionTitle: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <h3 className="my-4 text-3xl font-extrabold leading-8 tracking-tight text-center text-gray-300 md:text-4xl">
      {children}
    </h3>
  );
};
