import { SectionTitle } from "./common/SectionTitle";
import React from "react";
import getConfig from "next/config";
import dynamic from "next/dynamic";
import { CheckIcon } from "@heroicons/react/outline";
import { SubSectionTitle } from "./common/SubSectionTitle";
// @ts-ignore
import Pulse from "react-reveal/Pulse";

import { ContractData } from "../types/ContractData";
import { PhaseIntroduction } from "./mint/PhaseIntroduction";
import { Tier } from "../types/Tier";
import { TierItem } from "./mint/TierItem";
import { TimeBox } from "./mint/TimeBox";
import { activeYear } from "../constants";

const CountdownWithNoSSR = dynamic(() => import("react-countdown"), {
  ssr: false,
});

const { publicRuntimeConfig } = getConfig();

declare global {
  interface Window {
    ethereum: any;
    web3: any;
  }
}

const features = [
  {
    name: "Whitelist x 100",
    description:
      "There is only one round of presale with a total of 100 whitelisted addresses. The whitelist will be done in our Discord channel.",
  },
  {
    name: "Presale x 450",
    description:
      "Only those who are in the whitelist can join the presale. Every whitelisted address can mint three tickets at a presale price. The total supply for the presale round is 450.",
  },
  {
    name: "Public Sale x 4650",
    description:
      "There is no minting limit during the public sale. Since the supply of the tickets is limited to 4650, please reserve a time to mint to guarantee the offer within the period.",
  },
  {
    name: "Benefit by Tier",
    description:
      "There is no overlap across tiers. The age cutoff bases on January every year. Tier Starter and Premium have a potential higher appreciation rate and Tier Elite has proven itself passing a jump out.",
  },
  {
    name: "Fractional Ownership",
    description:
      "Ownership is proportional to the total supply of each tier. For example, if you have all the 600 tickets of Tier Elite, it means you are owning the entire NFT racehorse.",
  },
  {
    name: "Fountainhead Supply",
    description:
      "The total supply of each tier is equal to an entire racehorse or equivalent. Maxima and its partners currently supply more than 5 horses per tier for redemption. More redeem options will be provided in the future.",
  },
  {
    name: "Seamless Ticket Redemption",
    description: `The redeem page will be available in Q3 ${activeYear}. You will be able to select the available horses by the ticket tier to redeem. After the redemption you will be guarantee an early access in our marketplace.`,
  },
  {
    name: "World-Class Service Provider",
    description:
      "Maxima is a revolutionary Horse ecosystem which aims to collaborate with world-wide horsing racing stakeholders, currently the number of partnerships Maxima formed keeps increasing.",
  },

  {
    name: "Racehorse Management",
    description:
      "The total management fee of the racehorse is already covered until their retirement. The average retirement age for a racehorse is approximately 5 years old.",
  },
];

const Mint = (props: { contractData: ContractData }) => {
  const [isMinting, setIsMinting] = React.useState(false);
  const { presaleStartDateString, publicStartDateString, isOpenForWhitelist } =
    publicRuntimeConfig.campaign.nft;
  const presaleStartTime = new Date(presaleStartDateString).getTime();
  const publicSaleStartTime = new Date(publicStartDateString).getTime();
  const isCampaignActive = !(
    props.contractData.isStarterPaused &&
    props.contractData.isPremiumPaused &&
    props.contractData.isElitePaused
  );

  const countdownDate = props.contractData.isPresale
    ? presaleStartTime
    : publicSaleStartTime;

  React.useEffect(() => {
    // Static data for debugging
    console.table({
      presaleStartTime,
      publicSaleStartTime,
      countdownDate,
      isPresale: props.contractData.isPresale,
    });

    console.debug({
      starter: props.contractData.starter,
      premium: props.contractData.premium,
      elite: props.contractData.elite,
    });
  }, []);

  return (
    <section className="pb-16 mx-auto anchor-mint lg:max-w-7xl">
      <SectionTitle subText={`Starting in Q4 ${activeYear}`} isSubTextAbove>
        Mint Your Racehorse Ticket
      </SectionTitle>
      <div className="flex flex-col items-center">
        <CountdownWithNoSSR
          date={countdownDate}
          renderer={({ days, hours, minutes, seconds, completed }) => {
            if (completed) {
              return null;
            }

            return (
              <div className="pb-4">
                <div className="grid grid-flow-col gap-2 text-center lg:gap-5 auto-cols-max">
                  <TimeBox label="DAYS" value={days} />
                  <TimeBox label="HOURS" value={hours} />
                  <TimeBox label="MINS" value={minutes} />
                  <TimeBox label="SECS" value={seconds} />
                </div>
              </div>
            );
          }}
        />
      </div>
      <div className="grid gap-2 lg:grid-cols-3">
        <TierItem
          label="TIER STARTER"
          description="Weanling or Yearling. Age between 0.5 to 2. Their racing ability has
        not been exposed."
          tier={Tier.starter}
          video="/nfts/tier-starter-lite.mp4"
          preview="/nfts/tier-starter.jpg"
          color="blue-400"
          isPresale={props.contractData.isPresale}
          isPaused={props.contractData.isStarterPaused}
          presale={props.contractData.starter.presale}
          publicSale={props.contractData.starter.publicSale}
          maxTicketsPerWallet={10}
          isCampaignActive={isCampaignActive}
          isOpenForWhitelist={isOpenForWhitelist}
          isMinting={isMinting}
          setIsMinting={setIsMinting}
        />
        <TierItem
          label="TIER PREMIUM"
          description="2 to 3 years old ready to race horse. Grown up to 95% of their full adult
          height."
          tier={Tier.premium}
          video="/nfts/tier-premium-lite.mp4"
          preview="/nfts/tier-premium.png"
          isPresale={props.contractData.isPresale}
          isPaused={props.contractData.isPremiumPaused}
          presale={props.contractData.premium.presale}
          publicSale={props.contractData.premium.publicSale}
          isCampaignActive={isCampaignActive}
          isOpenForWhitelist={isOpenForWhitelist}
          maxTicketsPerWallet={5}
          color="gray-200"
          isMinting={isMinting}
          setIsMinting={setIsMinting}
        />
        <TierItem
          label="TIER ELITE"
          description="Junior classified racehorse that is eligible to immediately join a
          claiming race or above."
          tier={Tier.elite}
          video="/nfts/tier-elite-lite.mp4"
          preview="/nfts/tier-elite.jpg"
          maxTicketsPerWallet={3}
          color="maxima-secondary"
          isPresale={props.contractData.isPresale}
          isPaused={props.contractData.isElitePaused}
          presale={props.contractData.elite.presale}
          publicSale={props.contractData.elite.publicSale}
          isCampaignActive={isCampaignActive}
          isOpenForWhitelist={isOpenForWhitelist}
          isMinting={isMinting}
          setIsMinting={setIsMinting}
        />
      </div>
      <Pulse>
        <PhaseIntroduction />
      </Pulse>
      <Pulse>
        <div className="mx-auto">
          <div className="px-4 py-12 mx-2 my-24 bg-green-900 rounded-2xl max-w-7xl lg:px-8">
            <div className="text-center">
              <SubSectionTitle>Redeemable NFT Tickets Sales</SubSectionTitle>
              <p className="max-w-3xl mt-4 text-xl antialiased text-gray-400 lg:mx-auto">
                Be the first to be the world first NFT racehorse owner. It is
                also the early access ticket to our NFT Ecosystem, enjoying
                exclusive offers, Airdrop opportunities and more.
              </p>
            </div>

            <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-3 lg:gap-x-8">
              {features.map((feature) => (
                <div key={feature.name} className="relative">
                  <dt>
                    <CheckIcon
                      className="absolute w-6 h-6 text-green-500"
                      aria-hidden="true"
                    />
                    <p className="text-lg font-medium leading-6 text-gray-200 ml-9">
                      {feature.name}
                    </p>
                  </dt>
                  <dd className="mt-2 text-base text-gray-400 ml-9">
                    {feature.description}
                  </dd>
                </div>
              ))}
            </dl>

            <div
              onClick={() => {
                window.open("/nft-ticket/terms-and-conditions", "_blank");
              }}
              className="flex justify-center mt-12 space-x-3 text-gray-400 cursor-pointer hover:text-maxima-secondary-400"
            >
              <div>
                <button className="text-xl">Terms and Conditions</button>
              </div>
              <div className="mt-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </Pulse>
    </section>
  );
};

export default Mint;
