type Payload = {
  address: string;
  tier: string;
  price: string;
  amount: number;
  isPresale: boolean;
};

export const notifyMint = async (payload: Payload) => {
  fetch("/api/webhook/slack", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then(() => {
      console.info("Mint Success.");
    })
    .catch((e) => {
      console.error(e);
    });
};
