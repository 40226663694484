import React from "react";
import getConfig from "next/config";
import toast from "react-hot-toast";
import * as gtag from "../../services/gtag";
import { useWeb3, useSwitchNetwork } from "@3rdweb/hooks";
import { TierSaleStage } from "../../types/ContractData";
import { QuantitySelector } from "../mint/QuantitySelector";

// @ts-ignore
import Pulse from "react-reveal/Pulse";

// @ts-ignore
import MaximaNftArtifact from "../../artifacts/src/contracts/MaximaTicket.sol/MaximaTicket.json";
import Web3 from "web3";

import BigNumber from "bignumber.js";
import { notifyMint } from "../../helpers/notifyMint";
import { Tier } from "../../types/Tier";

const { publicRuntimeConfig } = getConfig();

const ERROR_MESSAGE_MAP: Record<string, string> = {
  NoEthereumProviderError: "Please install Metamask",
  UnsupportedChainIdError: "Please swtich to mainnet and reconnect",
};

enum BlockchainNetwork {
  mainnet = 1,
  rinkeby = 4,
}

type TierProps = {
  label: string;
  description: string;
  tier: Tier;
  video: string;
  preview: string;
  maxTicketsPerWallet: number;
  color: string;
  isPaused: boolean;
  isPresale: boolean;
  presale: TierSaleStage;
  publicSale: TierSaleStage;
  isCampaignActive: boolean;
  isOpenForWhitelist: boolean;
  isMinting: boolean;
  setIsMinting: (isMinting: boolean) => void;
};

export const TierItem: React.FC<TierProps> = (props) => {
  const { blockchainSetting, campaign } = publicRuntimeConfig;
  const { connectWallet, chainId, address, error } = useWeb3();
  const { switchNetwork } = useSwitchNetwork();
  const [selectedAmountOfNfts, setSelectedAmountOfNfts] = React.useState(
    props.isPresale ? 1 : props.maxTicketsPerWallet
  );
  const currentPrice = props.isPresale
    ? props.presale.cost
    : props.publicSale.cost;
  const totalMinted = props.presale.minted + props.publicSale.minted;
  const totalSupply = props.presale.supply + props.publicSale.supply;
  const soldPercentage = props.isPresale
    ? `${(props.presale.minted / props.presale.supply) * 100}%`
    : `${(totalMinted / totalSupply) * 100}%`;
  const remainingSupply = props.isPresale
    ? props.presale.supply - props.presale.minted
    : totalSupply - totalMinted;

  React.useEffect(() => {
    // Static state for debugging
    console.table({
      tier: props.tier,
      isPaused: props.isPaused,
      totalSupply,
      totalMinted,
      soldPercentage,
      remainingSupply,
      useMockContractData: campaign.nft.useMockContractData,
    });
  }, []);

  if (error) {
    toast.error(ERROR_MESSAGE_MAP[error.name] ?? error.message, {
      id: error.name,
    });
  }

  const getButtonLabel = () => {
    if (props.isMinting) {
      return `Minting NFT...`;
    }

    if (props.isPaused) {
      return "Stay Tuned";
    }

    if (!props.isCampaignActive || props.isOpenForWhitelist) {
      return "Join Discord";
    }

    if (!address) {
      return "Connect Wallet";
    }

    if (blockchainSetting.chainId !== chainId) {
      return `Switch to ${getChainNameById(blockchainSetting.chainId)}`;
    }

    return `Mint ${props.tier}`;
  };

  const getTierProps = () => {
    const disabled =
      !props.isCampaignActive || props.isPaused || props.isMinting;

    return {
      disabled,
      onClick: () => {
        if (props.isPaused) {
          console.warn("Sales Paused");
          return;
        }

        if (!props.isCampaignActive || props.isOpenForWhitelist) {
          gtag.event({
            action: "GetNftWhitelist",
            category: "NFT",
            label: props.tier,
          });

          window?.open("https://discord.gg/maxima", "_blank");
          return;
        }

        if (!address) {
          gtag.event({
            action: "ConnectMetaMask",
            category: "NFT",
            label: props.tier,
          });

          connectWallet("injected");
          return;
        }

        if (blockchainSetting.chainId !== chainId) {
          switchNetwork(blockchainSetting.chainId);
          return;
        }

        mintToken();
      },
      label: getButtonLabel(),
      buttonStyle:
        props.tier === Tier.starter
          ? {
              borderColor: disabled ? "rgb(55 65 81)" : "#63b3ed",
              ":hover": {
                borderColor: "#ecc94b",
              },
            }
          : undefined,
      buttonWrapperStyle:
        props.tier === Tier.starter
          ? {
              color: "#63b3ed",
              "&:hover": {
                color: "#ecc94b",
                borderColor: "#ecc94b",
              },
            }
          : undefined,
    };
  };

  const mintToken = async () => {
    try {
      if (!address) {
        throw new Error("Unexpected missing address during mintToken");
      }

      const { ethereum } = window;

      if (!ethereum) {
        throw new Error("Unexpected missing window.ethereum during mintToken");
      }

      window.web3 = new Web3(window.ethereum);
      const web3 = window.web3;

      if (!web3) {
        throw new Error("Unexpected missing window.web3 during mintToken");
      }

      const maximaTicketContract = new web3.eth.Contract(
        MaximaNftArtifact.abi,
        blockchainSetting.contractAddress
      );

      gtag.event({
        action: "PressMint",
        category: "NFT",
        label: props.tier,
        value: {
          tier: props.tier,
          address,
          price: currentPrice,
          amount: selectedAmountOfNfts,
        },
      });

      const checkIsWhitelisted = (address: string) => {
        return new Promise((resolve, reject) => {
          maximaTicketContract.methods
            .isWhitelisted(address)
            .call((error: any, result: boolean) => {
              if (error) {
                console.error(error);
                reject(false);
                return;
              }

              resolve(result);
            });
        });
      };

      await web3.eth.personal
        .sign(
          `
  Welcome to Maxima NFT Ticket!
  
  Click "Sign" to authenticiate this transaction.
  This request will not trigger a blockchain transaction
  or cost any gas fees.
  
  I accept the Maxima NFT Ticket Terms of Service:
  https://maxima.horse/nft-ticket/terms-and-conditions
  `,
          address,
          "MaximaTicketMint"
        )
        .then(async (signInfo: any) => {
          props.setIsMinting(true);
          const toastId = toast.loading("Minting your NFT...");

          const isWhitelisted = await checkIsWhitelisted(address);

          if (props.isPresale && !isWhitelisted) {
            props.setIsMinting(false);
            toast.error(
              "You were not whitelisted. Please wait for the public sale!",
              { id: toastId }
            );
            return;
          }

          gtag.event({
            action: "SignedMint",
            category: "NFT",
            label: props.tier,
            value: {
              tier: props.tier,
              address,
              price: currentPrice,
              amount: selectedAmountOfNfts,
            },
          });

          const getMintMethod = () => {
            if (props.tier === Tier.starter) {
              return maximaTicketContract.methods.mintStarter;
            }

            if (props.tier === Tier.premium) {
              return maximaTicketContract.methods.mintPremium;
            }

            if (props.tier === Tier.elite) {
              return maximaTicketContract.methods.mintElite;
            }

            throw new Error(`Unexpected mint method by tier ${props.tier}`);
          };

          const mint = getMintMethod();

          await mint(selectedAmountOfNfts)
            .send({
              from: address,
              value: web3.utils.toWei(
                `${new BigNumber(currentPrice).times(
                  new BigNumber(selectedAmountOfNfts)
                )}`
              ),
            })
            .on("receipt", function (receipt: any) {
              props.setIsMinting(false);

              const payload = {
                tier: props.tier,
                address,
                price: currentPrice,
                amount: selectedAmountOfNfts,
                isPresale: props.isPresale,
              };

              gtag.event({
                action: "MintSuccess",
                category: "NFT",
                label: props.tier,
                value: payload,
              });

              notifyMint(payload);

              toast(
                (t) => (
                  <div>
                    <div>
                      Successfully Mint Tier {props.tier} x{" "}
                      {selectedAmountOfNfts}!
                    </div>
                    <div>
                      <span className="mr-1">View</span>
                      <a
                        href={`${blockchainSetting.service.opensea.baseUrl}/${address}`}
                        rel="noreferrer"
                        target="_blank"
                        className=" text-maxima-secondary hover:underline"
                      >
                        the minted item(s)
                      </a>
                      <span className="mx-1">or</span>
                      <a
                        href={`${blockchainSetting.service.etherscan.baseUrl}/tx/${receipt.transactionHash}`}
                        rel="noreferrer"
                        target="_blank"
                        className=" text-maxima-secondary hover:underline"
                      >
                        your transaction
                      </a>
                      <span className="mx-1">or</span>
                      <a
                        href={`${blockchainSetting.service.opensea.baseUrl}/collection/maxima-ticket-nft`}
                        rel="noreferrer"
                        target="_blank"
                        className=" text-maxima-secondary hover:underline"
                      >
                        the NFT collection
                      </a>
                    </div>
                    <div className="mt-4">
                      <button
                        className="text-gray-400 cursor-pointer hover:text-gray-600"
                        onClick={() => toast.dismiss(t.id)}
                      >
                        Click here to dismiss
                      </button>
                    </div>
                  </div>
                ),
                {
                  id: toastId,
                  icon: "👏",
                  style: {
                    minWidth: "400px",
                  },
                  duration: 1000 * 30,
                }
              );
            })
            .on("error", function (error: any) {
              props.setIsMinting(false);

              gtag.event({
                action: "FailedMint",
                category: "NFT",
                label: props.tier,
                value: {
                  tier: props.tier,
                  address,
                  price: currentPrice,
                  amount: selectedAmountOfNfts,
                  error,
                },
              });

              console.error(error);
              toast.error(error.message, { id: toastId });
            });
        })
        .catch((err: any) => {
          gtag.event({
            action: "RefuseSignMint",
            category: "NFT",
            label: props.tier,
            value: {
              tier: props.tier,
              address,
              price: currentPrice,
              amount: selectedAmountOfNfts,
              error: err,
            },
          });
        });
    } catch (err: any) {
      toast.error(err.message);

      gtag.event({
        action: "ErrorMint",
        category: "NFT",
        label: props.tier,
        value: {
          tier: props.tier,
          address,
          price: currentPrice,
          amount: selectedAmountOfNfts,
          error: err,
        },
      });

      throw err;
    }
  };

  const tierProps = getTierProps();
  const buttonSpinner = (
    <div className="w-4 h-4 ml-4 border-b-2 border-gray-100 rounded-full animate-spin"></div>
  );

  return (
    <Pulse>
      <div className="flex flex-col items-center">
        <div>
          <video
            autoPlay
            muted
            onMouseOver={(e) => {
              // @ts-ignore
              e.target.play();
            }}
            onMouseOut={(e) => {
              // @ts-ignore
              e.target.pause();
            }}
            loop
            src={props.video}
            poster={props.preview}
          />
        </div>
        <h3 className="text-2xl font-extrabold tracking-tight text-gray-300 md:text-4xl">
          {props.label}
        </h3>
        <p className="py-4 text-center text-gray-400 h-22 w-80">
          {props.description}
        </p>

        {!props.isPresale ? (
          <p className="text-2xl font-extrabold text-gray-400">
            {currentPrice} ETH
          </p>
        ) : (
          <>
            <p className="text-2xl font-extrabold text-yellow-400">
              {props.presale.cost} ETH
            </p>
            <p className="text-2xl font-extrabold text-gray-600 line-through">
              {props.publicSale.cost} ETH
            </p>
          </>
        )}

        <p className="text-2xl font-extrabold text-gray-400">
          {props.presale.supply + props.publicSale.supply} Tickets
        </p>

        {!props.isOpenForWhitelist && !props.isPresale && (
          <div className="pt-2">
            <QuantitySelector
              min={1}
              max={Math.min(props.maxTicketsPerWallet, remainingSupply)}
              showMax
              onChange={(value) => setSelectedAmountOfNfts(value)}
            />
          </div>
        )}

        {props.isCampaignActive && !props.isOpenForWhitelist && (
          <div className="w-3/5 m-4 bg-gray-400 rounded-full dark:bg-gray-700">
            <div
              className={`p-0.5 text-xs font-medium leading-none text-center text-blue-100 rounded-full bg-yellow-200`}
              style={{ width: soldPercentage }}
            />
          </div>
        )}

        <div className={`mt-6`} style={tierProps.buttonWrapperStyle}>
          <button
            style={tierProps.buttonStyle}
            className={`flex items-center justify-center px-6 py-3 font-medium text-${props.color} toggle-tier disabled:text-gray-700 disabled:border-gray-700 border border-${props.color} rounded-md  hover:text-yellow-500`}
            onClick={tierProps.onClick}
            disabled={tierProps.disabled}
          >
            {tierProps.label}
            {props.isMinting ? buttonSpinner : null}
          </button>
        </div>
      </div>
    </Pulse>
  );
};

const getChainNameById = (chainId: number) => {
  if (chainId === BlockchainNetwork.mainnet) {
    return "Mainnet";
  }

  if (chainId === BlockchainNetwork.rinkeby) {
    return "Rinkeby";
  }

  return "Unknown network";
};
