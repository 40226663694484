import React from "react";

type Props = {
  onChange: (value: number) => void;
  defaultNumber?: number;
  min?: number;
  max?: number;
  showMax?: boolean;
};

export const QuantitySelector: React.FC<Props> = (props) => {
  const [currentNumber, setCurrentNumber] = React.useState(
    props.defaultNumber ?? props.max ?? props.min ?? 1
  );

  const isCurrentlyMin = currentNumber === props.min ? props.min : 0;
  const isCurrentlyMax = props.max ? currentNumber === props.max : false;

  const onDecrement = () => {
    if (currentNumber === 1) {
      return;
    }

    setCurrentNumber(currentNumber - 1);
    props.onChange(currentNumber - 1);
  };

  const onIncrement = () => {
    if (props.max && currentNumber === props.max) {
      return;
    }

    setCurrentNumber(currentNumber + 1);
    props.onChange(currentNumber + 1);
  };

  return (
    <div className="flex items-center">
      <div
        className={`p-2 text-gray-100 ${
          isCurrentlyMin
            ? "text-gray-500"
            : "cursor-pointer hover:text-maxima-secondary"
        } `}
        onClick={onDecrement}
      >
        <MinusIcon />
      </div>
      <div className="p-2">
        <span className="text-2xl text-gray-100">{currentNumber}</span>
        {props.showMax && (
          <span className="text-2xl text-gray-100"> / {props.max}</span>
        )}
      </div>
      <div
        className={`p-2 text-gray-100 ${
          isCurrentlyMax
            ? "text-gray-500"
            : "cursor-pointer hover:text-maxima-secondary"
        }`}
        onClick={onIncrement}
      >
        <PlusIcon />
      </div>
    </div>
  );
};

const MinusIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="w-6 h-6 "
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M18 12H6"
    />
  </svg>
);

const PlusIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="w-6 h-6 "
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 6v6m0 0v6m0-6h6m-6 0H6"
    />
  </svg>
);
