import React from "react";
export const PhaseIntroduction = () => {
  return (
    <div className="mt-24">
      <div className="px-4 py-16 mx-auto max-w-7xl sm:py-24 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 mt-6 gap-y-6 sm:grid-cols-2 sm:grid-rows-2 sm:gap-x-6 lg:gap-8">
          <div className="overflow-hidden border-8 border-yellow-100 border-double rounded-xl group aspect-w-2 aspect-h-1 sm:aspect-h-1 sm:aspect-w-1 sm:row-span-2">
            <img
              src="/assets/mint_display_1.png"
              className="object-cover object-center sm:absolute sm:inset-0 sm:w-full sm:h-full"
            />
            {/* <Image
              src={mintDisplay1}
              placeholder="blur"
              alt="Step 1"
              className="object-cover object-center sm:absolute sm:inset-0 sm:w-full sm:h-full"
            /> */}
            <div
              aria-hidden="true"
              className="opacity-50 bg-gradient-to-b from-transparent to-black"
            />
            <div className="flex items-end p-6">
              <div>
                <h3 className="text-2xl font-semibold text-white">
                  <span className="absolute inset-0" />
                  -- 01 --
                </h3>
                <p aria-hidden="true" className="mt-1 text-2xl text-white">
                  Choose Your Ticket
                </p>
              </div>
            </div>
          </div>
          <div className="overflow-hidden border-8 border-yellow-300 border-double rounded-xl group aspect-w-2 aspect-h-1 sm:relative sm:aspect-none sm:h-full">
            <img
              src="/assets/mint_display_2.png"
              className="object-cover object-center sm:absolute sm:inset-0 sm:w-full sm:h-full"
            />
            <div
              aria-hidden="true"
              className="opacity-50 bg-gradient-to-b from-transparent to-black sm:absolute sm:inset-0"
            />
            <div className="flex items-end p-6 sm:absolute sm:inset-0">
              <div>
                <h3 className="text-2xl font-semibold text-white">
                  <span className="absolute inset-0" />
                  -- 02 --
                </h3>
                <p aria-hidden="true" className="mt-1 text-2xl text-white">
                  Redeem Horse
                </p>
              </div>
            </div>
          </div>
          <div className="overflow-hidden border-8 border-yellow-500 border-double rounded-xl group aspect-w-2 aspect-h-1 sm:relative sm:aspect-none sm:h-full">
            <img
              src="/assets/mint_display_3.png"
              className="object-cover object-center sm:absolute sm:inset-0 sm:w-full sm:h-full"
            />
            <div
              aria-hidden="true"
              className="opacity-50 bg-gradient-to-b from-transparent to-black sm:absolute sm:inset-0"
            />
            <div className="flex items-end p-6 sm:absolute sm:inset-0">
              <div>
                <h3 className="text-2xl font-semibold text-white">
                  <span className="absolute inset-0" />
                  -- 03 --
                </h3>
                <p aria-hidden="true" className="mt-1 text-2xl text-white">
                  Trade, Manage, and Race
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
