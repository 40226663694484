import React from "react";

export const TimeBox = (props: { label: string; value: number }) => {
  return (
    <div className="flex flex-col w-20 p-2 bg-gray-500 rounded-lg lg:w-36 lg:p-4 text-neutral-content">
      <span className="font-mono text-3xl text-gray-300 lg:text-8xl countdown">
        <span>{`${props.value}`.padStart(2, "0")}</span>
      </span>
      <span className="text-gray-800">{props.label}</span>
    </div>
  );
};
